
















































import {
  Component, Vue, Watch, Prop
} from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Models } from '@mtap-smartcity/api';
import { CircuitsAction, CircuitsActionType, CircuitsState } from '@/store/modules/circuits/types';
import { AppState } from '@/store/modules/app/types';
import { sort } from '@/utils/sort';
import { nameFilter } from '@/utils/name_filter';
import EditableListItem from '@/components/base/EditableListItem.vue';

const circuits = namespace('circuits');
const app = namespace('app');

@Component({
  components: {
    EditableListItem,
  },
})
export default class EditableCircuitsList extends Vue {
  modifiedCircuit: Models.Circuits.Model | null = null;

  deleteData: Models.Circuits.Model | null = null;

  key = 1;

  @Prop(Boolean) readonly permissionCheckWrite!: boolean;

  @Prop(Boolean) readonly permissionCheckDelete!: boolean;

  @app.State
  userActionStatus!: AppState['userActionStatus'];

  @circuits.State
  circuits!: CircuitsState['circuits'];

  @circuits.State
  selectedCircuitID!: CircuitsState['selectedCircuitID'];

  @circuits.Action(CircuitsAction.SetSelectedCircuitID)
  setSelectedCircuitID!: CircuitsActionType['SET_SELECTED_CIRCUIT_ID']

  @circuits.Action(CircuitsAction.SetSelectedCircuitUuid)
  setSelectedCircuitUuid!: CircuitsActionType['SET_SELECTED_CIRCUIT_UUID']

  @circuits.Action(CircuitsAction.AddCircuitPlaceholder)
  addCircuitPlaceholder!: CircuitsActionType['ADD_CIRCUIT_PLACEHOLDER']

  @circuits.Action(CircuitsAction.RemoveCircuitPlaceholder)
  removePlaceholderCircuit!: CircuitsActionType['REMOVE_CIRCUIT_PLACEHOLDER']

  @circuits.Action(CircuitsAction.DeleteCircuit)
  deleteCircuit!: CircuitsActionType[CircuitsAction.DeleteCircuit]

  @circuits.Action(CircuitsAction.CreateCircuit)
  createCircuit!: CircuitsActionType[CircuitsAction.CreateCircuit]

  @circuits.Action(CircuitsAction.UpdateCircuit)
  updateCircuit!: CircuitsActionType[CircuitsAction.UpdateCircuit]

  get isPlaceholderCircuitInStore() {
    return this.circuits.some((c) => !c.id);
  }

  @Watch('modifiedCircuit')
  onModifiedCircuitChange() {
    this.setSelectedCircuitID(null);
    this.setSelectedCircuitUuid(null);
  }

  filteredCircuits(searchPhrase: string) {
    const sortedCircuits = [...this.circuits].sort((a, b) => sort(a.name, b?.name));
    return sortedCircuits.filter((c) => nameFilter(c.name, searchPhrase));
  }

  isModifiedCircuit(circuitId: number) {
    return this.modifiedCircuit && this.modifiedCircuit.id === circuitId;
  }

  isSelectedCircuit(circuitId: number) {
    return this.selectedCircuitID === circuitId;
  }

  eventHandlers(circuit) {
    return {
      select: () => this.selectItem(circuit),
      edit: () => this.editItem(circuit),
      discard: this.discard,
      save: this.save,
      modalAction: () => {
        this.deleteData = circuit;
      }
    };
  }

  selectItem(circuit: Models.Circuits.Model): void {
    if (this.userActionStatus.status === 'pending'
      || this.isPlaceholderCircuitInStore
      || this.modifiedCircuit) return;
    if (this.isSelectedCircuit(circuit.id!)) {
      this.setSelectedCircuitID(null);
      this.setSelectedCircuitUuid(null);
    } else {
      this.setSelectedCircuitID(circuit.id!);
      this.setSelectedCircuitUuid(circuit.uuid!);
    }
  }

  editItem(circuit: Models.Circuits.Model): void {
    this.modifiedCircuit = { ...circuit };
  }

  deleteItem(circuit: Models.Circuits.Model): void {
    this.deleteCircuit(circuit.uuid as string);
  }

  save(): void {
    if (!this.modifiedCircuit) {
      return;
    }
    if (!this.modifiedCircuit.id) {
      this.createCircuit(this.modifiedCircuit)
        .catch(() => {
          this.removePlaceholderCircuit();
        })
        .finally(() => {
          this.modifiedCircuit = null;
        });
    } else {
      this.updateCircuit(this.modifiedCircuit)
        .catch(() => {
          this.key += 1;
        })
        .finally(() => {
          this.modifiedCircuit = null;
        });
    }
    this.removePlaceholderCircuit();
  }

  discard(): void {
    if (!this.modifiedCircuit) {
      return;
    }
    if (!this.modifiedCircuit.id) {
      this.removePlaceholderCircuit();
    }
    this.modifiedCircuit = null;
    this.key += 1;
  }

  addCircuit(): void {
    if (!this.circuits.some((c) => !c.id)) {
      this.setSelectedCircuitID(null);
      this.setSelectedCircuitUuid(null);
      this.addCircuitPlaceholder();
      this.$nextTick(() => {
        this.modifiedCircuit = JSON.parse(JSON.stringify(this.circuits.find((c) => !c.id)));
      });
    }
  }

  circuitColor(c: Models.Circuits.Model) {
    if (this.modifiedCircuit && this.modifiedCircuit.id === c.id) {
      return this.modifiedCircuit.color;
    }
    return c.color;
  }

  mounted() {
    if (!this.selectedCircuitID && this.circuits.length) {
      const { id, uuid } = this.circuits.sort((a, b) => sort(a.name, b.name))[0];
      this.setSelectedCircuitID(id!);
      this.setSelectedCircuitUuid(uuid!);
    }
    const placeholder = this.circuits.find((c) => !c.id);
    if (placeholder) {
      this.modifiedCircuit = JSON.parse(JSON.stringify(placeholder));
    }
  }

  destroyed() {
    this.setSelectedCircuitID(null);
    this.setSelectedCircuitUuid(null);
    this.removePlaceholderCircuit();
  }
}
