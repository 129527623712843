var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SearchAndAddBox',{key:_vm.key,attrs:{"addOption":false,"searchPlaceholder":_vm._f("capitalize")(_vm.$t('actions.search', { element: _vm.$tc('main.circuit') })),"btnRemove":_vm.permissionCheckWrite && _vm.permissionCheckDelete},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var searchPhrase = ref.searchPhrase;
return _vm._l((_vm.filteredCircuits(searchPhrase)),function(c){return _c('EditableListItem',_vm._g({key:c.id,attrs:{"isSelected":_vm.isSelectedCircuit(c.id),"editState":_vm.isModifiedCircuit(c.id),"disabled":!!_vm.modifiedCircuit,"permissionCheckCreatorEdit":false,"permissionCheckCreatorDelete":false},scopedSlots:_vm._u([{key:"name",fn:function(){return [_c('BaseTextField',{attrs:{"value":c.name,"disabled":!_vm.isModifiedCircuit(c.id),"maxlength":35},on:{"input":function($event){_vm.modifiedCircuit.name = $event}}})]},proxy:true},{key:"extra",fn:function(){return [_c('BaseTooltip',{attrs:{"content":_vm._f("capitalize")(_vm.$t('main.circuitColor')),"position":"top"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({ref:("color-picker-activator-" + (c.id)),refInFor:true,staticClass:"color-picker-activator",style:({
            backgroundColor: _vm.circuitColor(c),
            cursor: _vm.isModifiedCircuit(c.id) ? 'pointer' : 'default'
          }),on:{"click":function($event){$event.stopPropagation();return _vm.togglePicker(c.id)}}},on))]}}],null,true)})]},proxy:true}],null,true)},_vm.eventHandlers(c)))})}}])})}
var staticRenderFns = []

export { render, staticRenderFns }